import React from "react";
import HomeDual from "../components/HomeDual/HomeDual";

export default function Homes() {
  return (
    <div className="">
      <HomeDual />
    </div>
  );
}
